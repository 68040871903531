import {Cookie} from '@/cookie.js';
import {chargeToken} from '@/token.js';
import {decrypt} from "@/hash.js";

export const Token = {
    get: (name) => {
        return chargeToken.getToken(name);
    },
    put: async (name, access_token, expires) => {
        return await chargeToken.generateToken(name, access_token, expires).then(async (res) => { return await res; });
    },
    cut: async (name=false) => {
        if(name) {
            Cookie.eraseCookie(name)
            return true
        }

        Cookie.deleteAllCookies()

        return true
    }
};


export const Entity = {
    construct(id, title) {
        this.id = id;
        this.title = title;
    },
    updated: (data) => {
        // Modify the storage data of the store
        // to which the user is connected
        localStorage.setItem('stId', data.id); // Set store ID
        localStorage.setItem('stTitle', data.title); // Set store title
        if (data.hasOwnProperty('brand_id')) {
            localStorage.setItem('stBrandId', data.brand_id); // Set brand ID if exist
        }
        if (data.hasOwnProperty('owner')) {
            localStorage.setItem('stGroup', data.owner); // Set owner ID if exist
        }

        return true;
    },
    all: function() {
        return {
            stId: localStorage.getItem('stId'),
            stTitle: localStorage.getItem('stTitle'),
            stBrandId: localStorage.getItem('stBrandId'),
        }
    },
    getId: function(ls=false) {
        // Retrieve the value of storage data
        if(localStorage.getItem('stId'))
            return localStorage.getItem('stId');
        else
            return '';
    },
    getTitle: function(ls=false) {
        // Retrieve the value of storage data
        if(localStorage.getItem('stTitle'))
            return localStorage.getItem('stTitle');
        else
            return '';
    },
    getBrandId: function(ls=false) {
        // Retrieve the value of storage data
        if(localStorage.getItem('stBrandId'))
            return localStorage.getItem('stBrandId');
        else
            return '';
    },
    getGroup: function(ls=false) {
        // Retrieve the value of storage data
        if(localStorage.getItem('stGroup'))
            return localStorage.getItem('stGroup');
        else
            return '';
    }
};

export const UserData = {
    get: function(key) {
        let getLocalStorageUserData = localStorage.getItem('_au');
        if(getLocalStorageUserData) {
            let parseLocalStorageUserData = JSON.parse(getLocalStorageUserData);
            if(parseLocalStorageUserData) {
                let getLocalStorageUserDataKey = parseLocalStorageUserData[key];
                if(getLocalStorageUserDataKey) {
                    return decrypt(getLocalStorageUserDataKey);
                }
            }
        }

        return false;
    }
}

export const TokenData = {
    get: function(key) {
        let getLocalStorageUserData = localStorage.getItem('_at');
        if(getLocalStorageUserData) {
            let parseLocalStorageUserData = JSON.parse(getLocalStorageUserData);
            if(parseLocalStorageUserData) {
                let getLocalStorageUserDataKey = parseLocalStorageUserData[key];
                if(getLocalStorageUserDataKey) {
                    return decrypt(getLocalStorageUserDataKey);
                }
            }
        }

        return false;
    }
}

export const AccessPrivateData = {
    get: function(key) {
        let getLocalStorageUserData = localStorage.getItem('_app');
        if(getLocalStorageUserData) {
            let parseLocalStorageUserData = JSON.parse(getLocalStorageUserData);
            if(parseLocalStorageUserData) {
                let getLocalStorageUserDataKey = parseLocalStorageUserData[key];
                if(getLocalStorageUserDataKey) {
                    return decrypt(getLocalStorageUserDataKey);
                }
            }
        }

        return false;
    }
}

import SelectStore from '@/views/Login/Modals/SelectStore';
import {Auth} from '@/auth';
import {encrypt, decrypt} from '@/hash';

export default {
  components: {
    SelectStore
  },
  data() {
    return {
      logo: process.env.VUE_APP_URL_LOGO,
      urlParams: new URLSearchParams(window.location.search),
      data: {},
      stores: [],
      isComponentModalInitPassword: false,
      isComponentModalStore: false,
      isComponentModalForgotPassword: false,
      viewAction: '',
      email: '',
      password: '',
      permission: 0,
      user: {},
      isLoading: false,
      emailMessage: '',
      passwordMessage: '',
      emailType: 'is-primary',
      passwordType: 'is-primary',
      newPassword: '',
      rePassword: '',
      initPasswordMessage: false,
      initPasswordType: 'is-primary',
      initRePasswordType: 'is-primary',
      repasswordType: 'is-primary',
      repasswordMessage: 'Veuillez utiliser un mot de passe de 12 caractères minimum avec au moins une majuscule, un caractère spécial et un chiffre.',
      newpasswordType: 'is-primary',
      newpasswordMessage: '',
      forgotType: 'is-primary',
      forgotMessage: '',
      codePasswordType: 'is-primary',
      codePasswordMessage: 'Veuillez utiliser un mot de passe de 12 caractères minimum avec au moins une majuscule, un caractère spécial et un chiffre.',
      validationCode: '',
      forgotAction: false,
      forgotPasswordLoading: false,
      forgotPasswordEmailDisabled: false,
      response: '',
      codeVerificationType: '',
      codeVerificationMessage: '',
      localStorageFP: localStorage.getItem('fp'),
    }
  },
  mounted() {
    this.viewAction = this.urlParams.get('action') || '';
    this.email = this.urlParams.get('email') || '';

    if (this.urlParams.get('fp') === "true") {
      this.isComponentModalForgotPassword = true;
      // this.email = this.urlParams.get('fp');
      this.forgotPasswordEmailDisabled = false;
      this.forgotAction = true;
      this.forgotType = 'is-success';
    }
  },
  methods: {
    passwordValidator: function(password) {
      let passwordArray = password.split('');
      let totalScore = 0;

      let rating = {
        number: 0,
        lowercase: 0,
        uppercase: 0,
        specialChar: 0,
        lengthMore: 0,
        total: 0
      }

      let validation = {
        isNumber: function(val){
          let pattern = /^\d+$/;
          return pattern.test(val);
        },
        isLowercase: function(val){
          let pattern = /[a-z]/;
          return pattern.test(val);
        },
        isUppercase: function(val){
          let pattern = /[A-Z]/;
          return pattern.test(val);
        },
        isSpecialChar: function(val){
          let pattern = /^[!@#\$%\^\&*\)\(+=._-]+$/g;
          return pattern.test(val);
        },
        isLengthMore: function(val){
          return val.length >= 12;
        }
      }

      for (let i=0; i<passwordArray.length; i++){
        if (validation.isNumber(passwordArray[i])){
          rating.number = 1;
        } else if (validation.isLowercase(passwordArray[i])){
          rating.lowercase = 1;
        } else if (validation.isUppercase(passwordArray[i])){
          rating.uppercase = 1;
        } else if (validation.isSpecialChar(passwordArray[i])){
          rating.specialChar = 1;
        }
      }
      if (validation.isLengthMore(password)) {
        rating.lengthMore = 1;
      }

      return rating;
    },
    openModalForgotPassword: function () {
      this.isComponentModalForgotPassword = !this.isComponentModalForgotPassword;
    },
    forgotPassword: function (e = false) {
      e.preventDefault();

      //this.isLoading = true;
      this.forgotPasswordLoading = true;

      if (!this.email) {
        this.forgotType = 'is-warning';
        this.forgotMessage = 'Ce champ est obligatoire.';
      }

      if (this.forgotType !== 'is-success') {
        this.$spauth.put('/forgot-password', {email: this.email.trim()})
          .then((data) => {
            this.forgotPasswordLoading = false;
            this.forgotAction = true;
            this.forgotType = 'is-success';
            this.forgotMessage = 'Un email vient d\'être envoyé';
            this.forgotPasswordEmailDisabled = true;
          })
          .catch((err) => {
            this.forgotAction = false;
            this.forgotType = 'is-danger';
            this.forgotMessage = 'Une erreur est survenue';
            this.forgotPasswordEmailDisabled = false;
          })
          .finally(() => {
            this.forgotPasswordLoading = false;
          });
      } else {
        let rating = this.passwordValidator(this.newPassword);

        rating.total = rating.number + rating.lowercase + rating.uppercase + rating.specialChar + rating.lengthMore;

        if (rating.total < 5) {
          this.codePasswordType = 'is-danger';
          this.codePasswordMessage = 'Veuillez utiliser un mot de passe de 12 caractères minimum avec au moins une majuscule, un caractère spécial et un chiffre.';
        } else if (rating.total === 5) {
          if (this.rePassword === this.newPassword) {
            this.$spauth.post('/confirm-forgot-password', {
              email: this.email.trim(),
              confirmation_code: this.validationCode.trim(),
              new_password: this.newPassword.trim()
            }).then((data) => {
              this.password = this.newPassword;
              this.signIn();

              this.isComponentModalForgotPassword = false;
            })
              .catch((err) => {
                this.codeVerificationType = 'is-danger';
                this.codeVerificationMessage = err.response.data.data;
              });
          } else {
            this.codePasswordType = 'is-danger';
            this.codePasswordMessage = 'Les mot de passe ne sont pas identique !';
          }
        }

        this.isLoading = false;
        this.forgotPasswordLoading = false;
      }
    },
    initPassword: function (e) {
      e.preventDefault();

      this.isLoading = true;

      if (!this.rePassword) {
        this.repasswordType = 'is-warning';
        this.repasswordMessage = 'Ce champ est obligatoire.';
      }

      if (!this.newPassword) {
        this.newpasswordType = 'is-warning';
        this.newpasswordMessage = 'Ce champ est obligatoire.';
      }

      if (this.rePassword === this.newPassword) {
        this.$spauth.post('/init-password', {
          auth_response: JSON.stringify(this.response),
          new_password: this.newPassword.trim(),
          password: this.rePassword.trim()
        })
          .then((data) => {
            if (data['data'] === 'Success') {
              this.password = this.rePassword;
              this.signIn();
              this.isComponentModalInitPassword = false;
            }
          })
          .catch((err) => {
            console.log('err:', err)
            this.initPasswordType = 'is-danger';
            if (err.response)
              this.initPasswordMessage = err.response.data.data
            else
              this.initPasswordMessage = err;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.repasswordType = 'is-danger';
        this.repasswordMessage = 'Les mot de passe ne sont pas identique !';
        this.newpasswordType = 'is-danger';
        this.newpasswordMessage = 'Les mot de passe ne sont pas identique !';
        this.isLoading = false;
      }
    },
    formSignIn: function (event) {
      event.preventDefault();

      this.signIn();
    },
    // Sign in method
    signIn: function () {
      this.isLoading = true; // Start loading

      this.emailType = 'is-primary';
      this.passwordType = 'is-primary';
      this.emailMessage = '';
      this.passwordMessage = '';

      // Checks if the email and password fields are filled in
      if (this.email.length === 0 || this.password.length === 0) {
        if (this.email.length === 0) {
          this.emailType = 'is-warning';
          this.emailMessage = 'Ce champ est obligatoire.';
        }

        if (this.password.length === 0) {
          this.passwordType = 'is-warning';
          this.passwordMessage = 'Ce champ est obligatoire.';
        }

        this.isLoading = false;
        return false;
      }

      Auth.sign_in(encrypt(this.email), encrypt(this.password), this.$session)
        .then(async (data) => {
          // Messages errors
          if (data.hasOwnProperty('code')) {
            if (data.code === 0) {
              this.emailType = 'is-danger';
              this.passwordType = 'is-danger';

              this.emailMessage = 'Votre compte est désactivé';

              this.isLoading = false;
              return;
            }
          }

          if (data.hasOwnProperty('response')) {
            if (data.response.status === 404) {
              this.emailType = 'is-danger';
              this.passwordType = 'is-danger';

              this.emailMessage = data.response.data.data;
              this.passwordMessage = data.response.data.data;

              this.isLoading = false;
              return;
            }
          }
          // Open the password initialization modal
          if (data.hasOwnProperty('ChallengeName')) {
            if (data['ChallengeName'] === 'NEW_PASSWORD_REQUIRED') {
              this.isComponentModalInitPassword = true;
              this.isLoading = false;
              this.response = data
              return this.response;
            }
          }

          // Start session
          this.$session.start();

          if (this.$session.exists()) {
            this.passwordType = 'is-success';
            this.emailType = 'is-success';
            this.emailMessage = '';
            this.passwordMessage = '';

            this.$store.state.given_name = data.given_name;
            this.$store.state.family_name = data.family_name;
            this.$store.state.operator = data.family_name + ' ' + data.given_name;
            this.$store.state.permission = parseInt(data['permission']);
            this.$store.state.regulation = parseInt(data['regulation']);
            this.$store.state.store = parseInt(data['store_connected']);

            this.permission = parseInt(data['permission']);

            const stores = JSON.parse(data['stores']);

            if ([10].includes(this.permission)) {
              this.$cx.get('/stores/get-store/' + stores[0])
                .then(({id, title, owner}) => {
                  this.$entity.updated({
                    id: id,
                    title: title,
                    owner: owner,
                  });

                  //window.location = this.$route.query.query ? this.$route.query.query : '/contacts';
                  this.$store.state.isAuthenticated = true;
                  this.$router.push(this.$route.query.query ? this.$route.query.query : '/');
                });
            } else if ([20].includes(this.permission)) {
              if (stores.length === 1) {
                this.$cx.get('/stores/get-store/' + stores[0])
                  .then(({id, title, owner}) => {
                    this.$entity.updated({
                      id: id,
                      title: title,
                      owner: owner,
                    });

                    //window.location = this.$route.query.query ? this.$route.query.query : '/';
                    this.$store.state.isAuthenticated = true;
                    this.$router.push(this.$route.query.query ? this.$route.query.query : '/');
                  });
              } else {
                this.user = {
                  email: encrypt(this.email),
                  password: encrypt(this.password),
                  permission: this.permission,
                  stores: stores,
                }

                this.isComponentModalStore = true;
              }
            } else if ([30, 40, 50].includes(this.permission)) {
              this.$store.state.groupStores = JSON.stringify(stores);
              this.user = {
                email: encrypt(this.email),
                password: encrypt(this.password),
                permission: this.permission,
                stores: stores,
              }

              this.isComponentModalStore = true;
            }
          } else {
            // Capture the error if the session is not created automatically
            this.$buefy.toast.open({
              duration: 10000,
              message: 'Une erreur est survenue lors de l\'initialisation de la session.',
              position: 'is-top',
              type: 'is-danger'
            });

            this.isLoading = false;
          }
        })
        .catch((err) => {
          // Catch the error while verifying email and password
          this.emailType = 'is-danger';
          this.passwordType = 'is-danger';

          this.isLoading = false;
        });
    }
  }
}

const permissions = [
  {
    id: 10,
    label: 'Hôte de caisse',
  },
  {
    id: 20,
    label: 'Responsable',
  },
  {
    id: 30,
    label: 'Adhérent',
  },
  {
    id: 40,
    label: 'Centrale',
  },
  {
    id: 50,
    label: 'Administrateur',
  },
]
export const Permission = {
  get: (id) => {
    /**
     *
     * @type {{id: string}}
     */
    let perm = permissions.find(r => parseInt(r.id) === parseInt(id))

    return perm ? perm['label'] : 'Inconnu'
  }
}

/// :TODO

export const Cookie = {
    setCookie: (name, value, seconds) => {
        let dt = new Date();
        let time = dt.getTime();
        let expireTime = time + 1000*seconds;
        dt.setTime(expireTime);
        let expires = "expires="+ dt.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + "; path=/";
    },
    getCookie: (name) => {
        let cname = name + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cname) === 0) {
                return c.substring(cname.length, c.length);
            }
        }
        return false;
    },
    eraseCookie: (name) => {
        document.cookie = name + '=; expires=0; path=/'
    },
    deleteAllCookies: () => {
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++)
            document.cookie = cookies[i].split("=")[0] + '=; expires=0; path=/'
    }
};

import {spauth} from '@/api';
import { decrypt, encrypt } from '@/hash';
import {Token} from "@/mixins/entity";

import * as Sentry from '@sentry/vue';

import {Permission} from '@/labels'

export const Auth = {
  sign_in: async (email, password, session=false, data=false) => {
    // Signin methods
    return await spauth.post('/sign-in', {email: decrypt(email), password: decrypt(password)})
      .then(({data}) => {
        if (data.is_active === '0')
          return {code: 0}
        if(
          data.hasOwnProperty('AccessToken') &&
          data.hasOwnProperty('ExpiresIn') &&
          data.hasOwnProperty('TokenType') &&
          data.hasOwnProperty('RefreshToken') &&
          data.hasOwnProperty('IdToken') &&
          data.hasOwnProperty('permission') &&
          data.hasOwnProperty('stores') &&
          data.hasOwnProperty('cx_token') &&
          data.hasOwnProperty('me_token')
        ) {

          session.start();

          if(session.exists()) {
            session.renew(session.id())
          }

          Sentry.setUser({
            email: String(decrypt(email)),
            username: [String(data['given_name']), String(data['family_name'])].join(' ').trim(),
            id: String(data['username']),
            permission: Permission.get(data['permission'])
          });

          let dts = new Date(), maxSeconds = 3600; // 1 hour
          dts.setSeconds( dts.getSeconds() + maxSeconds);

          // Token Access
          localStorage.setItem('_at', JSON.stringify({
            dts: encrypt(dts.getTime().toString()),
            tkme: encrypt(data['me_token'] ? data['me_token'] : ''),
            tkcx: encrypt(data['cx_token'] ? data['cx_token'] : ''),
            idtk: encrypt(data['IdToken'] ? data['IdToken'] : ''),
            actk: encrypt(data['AccessToken'] ? data['AccessToken'] : ''),
            snu: encrypt(data['sn_embed'] ? data['sn_embed'] : ''),
            lsu: encrypt(data['ls_embed'] ? data['ls_embed'] : ''),
          }));

          // User Access
          localStorage.setItem('_au', JSON.stringify({
            permission: encrypt(data['permission'] ? data['permission'] : ''),
            regulation: encrypt(data['regulation'] ? data['regulation'] : ''),
            given_name: encrypt(data['given_name'] ? data['given_name'] : ''),
            stores: encrypt(data['stores'] ? data['stores'] : ''),
            family_name: encrypt(data['family_name'] ? data['family_name'] : ''),
          }));

          // Personal Access Private
          localStorage.setItem('_app', JSON.stringify({
            z2: password,
            z3: email,
            z4: encrypt(data['username'])
          }));
        } else {
          /*return {code: 1}*/
        }

        return data;
      })
      .catch((err) => {
        Token.cut();

        return err;
      })
  },
  signOut: async (session) => {
    session.clear();
    session.destroy();
    localStorage.removeItem('_at');
    localStorage.removeItem('_au');
    localStorage.removeItem('_app');
    return true;
  },
  is: () => {
    // Return the connection statut
    return true;
    // if (this.$session.id()) {
    //   return true;
    // } else {
    //   return false;
    // }
  }
}

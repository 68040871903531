import Vue from 'vue'

import axios from 'axios'

import { Entity, TokenData } from './mixins/entity'
import store from "@/store";

const requests = axios.create({});
// TODO:
requests.interceptors.request.use(async (config) => {
  return config
}, (error) => {
  return Promise.reject(error)
});

// TODO:
requests.interceptors.response.use((response) => {
  return response.data || response
}, (error) => {
  return Promise.reject(error)
});

export {requests}
// TODO:
Vue.prototype.$requests = requests;


const spauth = axios.create({
  baseURL: process.env.VUE_APP_AUTHENTIFICATION_API_URL
});
// TODO:
spauth.interceptors.request.use(async (config) => {
  // Token ID Authentication API
  let idtk = await TokenData.get('idtk');
  if (idtk) {
    config.params = config.params || {};
    config.headers['Authorization'] = `Bearer ${idtk}`;
  }
  return config
}, (error) => {
  return Promise.reject(error)
});

// TODO:
spauth.interceptors.response.use((response) => {
  return response.data || response
}, (error) => {
  return Promise.reject(error)
});

export {spauth}
// TODO:
Vue.prototype.$spauth = spauth;

// TODO:
const cx =  axios.create({
  baseURL: process.env.VUE_APP_CX_API_URL,
});

// TODO:
cx.interceptors.request.use(async (config) => {
  config.headers['Authorization'] = await TokenData.get('tkcx');
  return config
}, (error) => {
  return Promise.reject(error)
});

// TODO:
cx.interceptors.response.use((response) => {
  return response.data
}, (error) => {
  if(error.response.status === 401) {
    store.state.reSignInUser = true
  }
  return Promise.reject(error)
});

// TODO:
Vue.prototype.$cx = cx;

// TODO:
const me = axios.create({
  baseURL: process.env.VUE_APP_ME_API_URL
});

// TODO:
me.interceptors.request.use(async (config) => {
  let token = process.env.VUE_APP_ENV === 'development' && process.env.VUE_APP_FORCE_TKME ? process.env.VUE_APP_FORCE_TKME : await TokenData.get('tkme')
  config.params = config.params || {};
  // Remove store filter parameter if storeFilter is false.
  if (config.params.storeFilter != false)
    config.params['store'] = Entity.getId();
  else
    delete config.params.storeFilter
  config.headers['Authorization'] = `Bearer ${ token }`;
  return config
}, (error) => {
  return Promise.reject(error)
});

// TODO:
me.interceptors.response.use((response) => {
  return response.data || response
}, (error) => {
  if(error.response.status === 401) {
    store.state.reSignInUser = true
  }
  return Promise.reject(error)
});

// TODO:
Vue.prototype.$me = me;

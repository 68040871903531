<template>
  <nav class="breadcrumb has-succeeds-separator is-size-7" aria-label="breadcrumbs">
    <ul>
      <li v-if="$route.query.backTo">
        <b-button
          @click="forceDirection"
          icon-left="square-arrow-left"
          size="is-small">
          Revenir sur la fiche précédente
        </b-button>
      </li>
      <li v-else v-for="breadcrumb in data" :class="{ 'is-active': $route.name === breadcrumb.name }">
        <router-link class="has-text-grey" aria-current="page" :to="{ name: breadcrumb.meta.nameMaster, query: $route.query }">
          {{ breadcrumb.meta.title }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumb",
  data () {
    return {
      data: [],
      isPublic: true
    }
  },
  methods: {
    forceDirection() {
      let routeData = this.$router.resolve({
        name: 'ContactCard',
        params: {
          contactId: this.$route.query.backTo
        }
      });

      document.location.href = routeData.href
    },
    breadcrumb: function (event) {
      this.data = []; // Empty data

      this.$route.matched.forEach(route => this.data.push(route));
    }
  },
  created() {
    this.breadcrumb()
  },
  watch: {
    $route() {
      this.breadcrumb()
    }
  }
}
</script>

<style scoped>

</style>

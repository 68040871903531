<template>
  <form id="selectStore">
    <div class="modal-card" style="width: 500px;">
      <header class="modal-card-head">
        <p class="modal-card-title is-size-5">Se connecter à un magasin</p>
      </header>
      <section class="modal-card-body" style="height: 150px;">
        <b-field
          v-if="user.permission >= 40">
          <b-autocomplete
            v-model="storeTitle"
            field="title"
            :data="stores"
            @typing="getAsyncData"
            required
            open-on-focus
            ref="autocomplete"
            placeholder="Rechercher un magasin par son nom"
            :loading="isFetching"
            :disabled="isLoading"
            @select="option => { if (option) {onChangeStore(option.id)} else {this.storeTitle = ''; this.store_selected = null} }"
            size="is-small"
            icon="search"
            icon-pack="far">

            <template slot-scope="props">
              <div class="media navbar-item-centered">
                <div class="media-left" style="margin-top: 7.5px" v-if="props.option.front_img_url">
                  <img class="media-logo" :src="props.option.front_img_url">
                </div>

                <div class="media-content">
                  {{ props.option.title }}

                  <br>

                  <small>
                    Magasin ID : <b>{{ props.option.id }}</b>&Tab;

                    <template v-if="props.option.is_pilote">
                      <span class="store-pilote"><i class="fas fa-vial"></i> Pilote</span>
                    </template>

                    <template v-else-if="props.option.is_active">
                      <span class="store-open"><i class="fas fa-store"></i> En service</span>
                    </template>

                    <template v-else>
                      <span class="store-close"><i class="fas fa-store-slash"></i> Fermé définitivement</span>
                    </template>
                  </small>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>

        <b-field v-else>
          <b-select
            placeholder="Choisissez un magasin"
            class="is-size-7"
            :loading="isLoading"
            :disabled="isLoading"
            @change.native="onChangeStore($event.target.value)">
            <option
              v-if="stores.length > 0"
              v-for="store in stores"
              :value="store.id"
              :key="store.id">
              {{ store.id }} - {{ store.title }}
            </option>
          </b-select>
        </b-field>
      </section>

      <footer class="modal-card-foot" style="justify-content: flex-end;">
        <b-button
          size="is-small"
          type="is-twitter"
          :loading="isLoading"
          :disabled="isLoading || isFetching || !store_selected || storeTitle.length <= 0"
          @click="validStore">
          Valider
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import debounce from 'lodash/debounce';
import {Auth} from '@/auth';
import {decrypt} from '@/hash';


export default {
  props: {
    user: {
      default: () => ({
        email: '',
        password: '',
        permission: 0,
        stores: []
      }),
      type: Object,
    }
  },
  data() {
    return {
      isLoading: false,
      stores: [],
      storeTitle: '',
      isFetching: false,
      store_selected: null,
    }
  },
  created() {
    if (this.$store.state.permission <= 30) {
      this.isLoading = true;

      this.$cx.get('/stores/get-store/' + this.user.stores[0])
        .then(({id, title, brand_id, is_active, is_pilote, groups}) => {
          if (groups) {
            this.stores = groups.filter(el => {
              if (this.user.stores.length > 1) {
                if (el.id !== this.user.stores[0] && this.user.stores.includes(el.id)) {
                  return el
                }
              } else {
                if (el.id !== this.user.stores[0]) {
                  return el
                }
              }
            });
          }

          // Push to stores collection the primary store.
          this.stores.push({
            id: id,
            title: title,
            brand_id: brand_id,
            is_active: is_active,
            is_pilote: is_pilote,
          });
        })
        .catch((err) => {
          console.log('err:', err);
        })
        .finally(() => {
          this.isLoading = false;
        })
    }
  },
  methods: {
    onChangeStore: function (storeId) {
      this.store_selected = this.stores.find(el => el.id === parseInt(storeId));

      this.storeTitle = this.store_selected.title;
    },
    validStore: async function () {
      this.isLoading = true;

      await this.$entity.updated({
        id: this.store_selected.id,
        title: this.store_selected.title,
        brand_id: this.store_selected.brand_id,
        owner: this.store_selected.owner
      });

      this.user.stores.unshift(this.store_selected.id)

      let data = {
        "email": decrypt(this.user.email),
        "custom:store_connected": this.store_selected.id,
      }

      this.$spauth.put('/update-account', data)
        .then((data) => {
          Auth.sign_in(this.user.email, this.user.password, this.$session, data)
            .then(() => {
              this.$store.state.isAuthenticated = true;
              this.$router.push(this.$route.query.query ? this.$route.query.query : '/');
            });
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    getAsyncData: debounce(function () {
      if (!this.storeTitle.length) {
        this.stores = [];
        this.storeTitle = '';
        return;
      }
      this.isFetching = true;

      this.$cx.get(`/stores/query-store/?q=${this.storeTitle}`)
        .then(({stores}) => {
          this.stores = [];

          if (stores) {
            stores.forEach((item) => {
              this.stores.push(item)
            });
          }

          return this.stores;
        })
        .catch((error) => {
          this.stores = [];
          throw error
        })
        .finally(() => {
          this.isFetching = false
        })
    }, 500),
  }
}
</script>

<style>
.media-logo {
  height: 32px;
  max-width: 32px;
}

.store-open {
  color: #1BB394;
}

.store-pilote {
  color: #1bb32a;
}

.store-close {
  color: #f14668;
}

#selectStore .modal-card {
  overflow: visible;
}

#selectStore .modal-card-body {
  overflow: visible;
}

#selectStore .dropdown-content {
  border-radius: 0;
}

#selectStore .autocomplete .dropdown-content {
  max-height: 167px!important;
}
</style>

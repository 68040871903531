import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import { getEnv } from './env';

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

const version = process.env.VUE_APP_VERSION;

const env = getEnv();

if(env !== 'development') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    release: "sp2k-store-portal@" + version,
    environment: env,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['portailmagasin.sport2000.fr', 'sport2000-storeportal-stage.lineup7-platform.fr'],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

Vue.prototype.$urlAssets = process.env.VUE_APP_URL_ASSETS;

// Import Entity
import { Entity } from './mixins/entity.js'

Vue.prototype.$entity = Entity;

import { Cookie } from './cookie.js';
Vue.prototype.$cookie = Cookie;

const Permission = {
  get: (name) => {
    return parseInt(router.options.routes.find(el => el.name === name).meta.permission);
  }
};

Vue.prototype.$permission = Permission;

// Import API and Axios Libary
import './api'

// Vue Session
import VueSession from 'vue-session';
Vue.use(VueSession, {
  persist: true
})

// Buefy library
import Buefy from 'buefy';
Vue.use(Buefy, {
  defaultIconPack: 'far',
  defaultMonthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août' ,'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  defaultDayNames: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  defaultFirstDayOfWeek: 1
});

// Moment Library
import Moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

Moment.locale('fr', {
  months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
  monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  monthsParseExact : true,
  weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
  weekdaysParseExact : true,
  longDateFormat : {
    LT : 'HH:mm',
    LTS : 'HH:mm:ss',
    L : 'DD/MM/YYYY',
    LL : 'D MMMM YYYY',
    LLL : 'D MMMM YYYY HH:mm',
    LLLL : 'dddd D MMMM YYYY HH:mm'
  },
  calendar : {
    sameDay : '[Aujourd’hui à] LT',
    nextDay : '[Demain à] LT',
    nextWeek : 'dddd [à] LT',
    lastDay : '[Hier à] LT',
    lastWeek : 'dddd [dernier à] LT',
    sameElse : 'L'
  },
  relativeTime : {
    future : 'dans %s',
    past : 'il y a %s',
    s : 'quelques secondes',
    m : 'une minute',
    mm : '%d minutes',
    h : 'une heure',
    hh : '%d heures',
    d : 'un jour',
    dd : '%d jours',
    M : 'un mois',
    MM : '%d mois',
    y : 'un an',
    yy : '%d ans'
  },
  dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
  ordinal : function (number) {
    return number + (number === 1 ? 'er' : 'e');
  },
  meridiemParse : /PD|MD/,
  isPM : function (input) {
    return input.charAt(0) === 'M';
  },
  meridiem : function (hours, minutes, isLower) {
    return hours < 12 ? 'PD' : 'MD';
  },
  week : {
    dow : 1, // Monday is the first day of the week.
    doy : 4  // Used to determine first week of the year.
  }
});

momentDurationFormatSetup(Moment)

Vue.prototype.$moment = Moment;

Vue.config.productionTip = false;

Vue.filter('pluralize', (word, amount) => (Math.abs(parseInt(amount)) > 1) ? `${word}s` : word);
Vue.filter('pluz', (word, amount, grouped=true) => (
  grouped ? amount + ' ' : '') + ((amount > 1) ? `${word}s` : word));

Vue.filter('channel', (id) => id === 1 ? '<i class="fas fa-at">' : '<i class="fas fa-mobile-alt">');

const channel = (x) => {
  if (x === 1)
    return 'at';
  else if (x === 2)
    return 'mobile-alt';
  else if (x === 3)
    return 'mailbox';
  else
    return 'question';
};

Vue.prototype.$channel = channel;

Vue.filter('store', (id) => id === 1 ? 'Bayonne' : 'Chambourcy');

Vue.filter('civility', (id) => id === 1 ? 'Monsieur' : 'Madame');

Vue.filter('formatDate', function(value) {
  return Moment(String(value)).format('DD MMMM');
});

Vue.filter('decimal', function(value) {
  return new Intl.NumberFormat('fr-fr', { style: 'decimal'}).format(value);
});

Vue.filter('floor', function(value) {
  if (!value) return 0;
  return Math.floor(value);
});

Vue.filter('price', function(value) {
  return new Intl.NumberFormat('fr-fr', { style: 'currency', currency: 'EUR' }).format(value);
});

Vue.filter('date', function(value, format_date=false) {
  if (!value) return '';
  return Moment(String(value)).format(format_date ? format_date : 'DD/MM/YYYY');
});

Vue.filter('datetime', function(value, format_date=false) {
  if (!value) return '';
  return Moment(String(value)).format(format_date ? format_date : 'DD/MM/YYYY à HH:mm:ss');
});

Vue.filter('capitalize', function (value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1)
});

Vue.filter('pourcentage', function (value) {
  return Math.round(value * 100) + ' %';
});

import { utils } from './utils';

Vue.prototype.$utils = utils;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

<template>
  <div @click="logOut">{{ label }}</div>
</template>

<script>
// Import Auth for use signOut function
import { Auth } from '@/auth';

export default {
  props: {
    label: {
      default: 'Se déconnecter',
    }
  },
  methods: {
    logOut() {
      Auth.signOut(this.$session)
        .then(() => {
          this.$buefy.snackbar.open({
            message: 'Déconnexion réussi !',
            type: 'is-success',
            position: 'is-top',
            duration: 2000,
            actionText: 'Ok',
          });

          this.$store.state.isAuthenticated = false;

          this.$router.push({name: 'SignIn'});
        });
    }
  }
}
</script>

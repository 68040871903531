import { Cookie } from "./cookie";

export const chargeToken = {
    async generateToken (name, access_token, expires) {
        Cookie.setCookie(name, access_token, parseInt(expires));

        return access_token;
    },
    getToken(name) {
        return Cookie.getCookie(name);
    }
};
